<style lang="scss">
.picker-gift {
  .sp-filter-form {
    padding: 8px 8px 0 8px;
  }

  .sp-finder-hd {
    display: none;
  }

  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-gift">
    <SpFilterForm :model="formData" size="small" @onSearch="onSearch" @onReset="onSearch">
      <SpFilterFormItem prop="item_name">
        <el-input v-model="formData.item_name" clearable placeholder="请输入商品名称" />
      </SpFilterFormItem>
      <SpFilterFormItem prop="item_bn">
        <el-input v-model="formData.item_bn" clearable placeholder="请输入商品货号" />
      </SpFilterFormItem>
    </SpFilterForm>
    <SpFinder
      ref="finder"
      reserve-selection
      :row-key="rowKey"
      :other-config="{
        'max-height': 460
      }"
      url="/physicalgifts/list"
      :show-pager-text="`已选中：${localSelection.length}`"
      fixed-row-action
      :setting="setting"
      :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }"
      @select="onSelect"
      @select-all="handleSelectAll"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import BasePicker from './base'
import PageMixin from '../mixins/page'
import { createSetting } from '@shopex/finder'

export default {
  name: 'PickerGift',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择赠品'
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data() {
    const { queryParams } = this.value
    const defaultParams = {
      item_name: '',
      item_bn: ''
    }
    const formData = Object.assign(defaultParams, queryParams)
    return {
      formData,
      multiple: this.value?.multiple ?? true,
      localSelection: [],
      rowKey: 'item_id'
    }
  },
  computed: {
    setting() {
      return createSetting({
        columns: [
          { name: '商品ID', key: 'item_id', width: 80 },
          {
            name: '商品名称',
            key: 'item_name',
            minWidth: 150
          },
          {
            name: '商品规格',
            key: 'item_spec_desc',
            minWidth: 150,
          },
          {
            name: '商品货号',
            key: 'item_bn',
            minWidth: 150,
          },
          {
            name: '售后策略',
            key: 'aftersales_rule_msg',
            minWidth: 150,
          },
          {
            name: '售后策略',
            key: 'goods_type',
            minWidth: 150,
            render: (h, { row }) => {
              return row.goods_type == 'normal' ? '实体赠品' : '虚拟赠品'
            }
          },
          {
            name: '售后金额',
            key: 'aftersales_price',
            minWidth: 150,
            formatter: (v) => v / 100
          },
          {
            name: '商品状态',
            key: 'approve_status',
            minWidth: 150
          }
        ]
      })
    }
  },
  watch: {
    localSelection: {
      handler(val) {
        this.updateVal(val)
      },
      deep: true
    }
  },
  created() {
    this.localSelection = cloneDeep(this.value.data) || []
    this.rowKey = this.value?.rowKey || 'item_id'
  },
  mounted() {
    this.multiple = this.value?.multiple ?? true
  },
  methods: {
    beforeSearch(params) {
      params = {
        ...params,
        ...this.formData
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.localSelection.length > 0) {
        const { finderTable } = this.$refs.finder.$refs
        const ids = this.localSelection.map((m) => m[this.rowKey])
        const selectRows = list.filter((item) => ids.includes(item[this.rowKey]))
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selectRows)
        })
      }
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onSelect(selection, row) {
      if (!this.multiple) {
        const { finderTable } = this.$refs.finder.$refs
        finderTable.clearSelection()
        this.localSelection = [row]
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selection.length > 0 ? [row] : [])
        })
      } else {
        const isAdd = selection.includes(row)
        const idx = this.localSelection.findIndex((f) => f[this.rowKey] === row[this.rowKey])
        if (isAdd && idx === -1) {
          this.localSelection.push(row)
        }
        if (!isAdd) {
          this.localSelection.splice(idx, 1)
        }
      }
    },
    /**
     * @description 全选只针对当前页 那么在状态改变的时候 应该不考虑未来的选中数据
     * @description 循环调用 onSelect 方法保存或删除当前页选中数据
     * @param list 当前页勾选数据 如果localSelection存在未来页数据 那么页码切换的时候 list中也会有
     */
    handleSelectAll(list) {
      // 当前页得数据
      const currentPageData = this.$refs.finder.$refs.finderTable.$refs.finderTable.list
      const currentPageDataIds = currentPageData.map((m) => m[this.rowKey])
      const currentPageSelectList = list.filter((item) => currentPageDataIds.includes(item[this.rowKey]))
      // list 中需要排除掉未来页的数据 才能保证当前页全选或者反选
      if (currentPageSelectList.length === 0) {
        currentPageData.forEach((item) => this.onSelect([], item))
      } else {
        currentPageData.forEach((item) => this.onSelect(currentPageData, item))
      }
    }
  }
}
</script>
