var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-goods"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[(_vm.isShowFormItem('distributor_id'))?_c('SpFilterFormItem',{attrs:{"prop":"distributor_id"}},[_c('SpSelectShop',{attrs:{"clearable":"","placeholder":"请选择店铺"},on:{"change":_vm.onSearch},model:{value:(_vm.formData.distributor_id),callback:function ($$v) {_vm.$set(_vm.formData, "distributor_id", $$v)},expression:"formData.distributor_id"}})],1):_vm._e(),_c('SpFilterFormItem',{attrs:{"prop":"keywords"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入商品名称"},model:{value:(_vm.formData.keywords),callback:function ($$v) {_vm.$set(_vm.formData, "keywords", $$v)},expression:"formData.keywords"}})],1),_c('SpFilterFormItem',{attrs:{"prop":"approve_status"}},[_c('el-select',{attrs:{"placeholder":"选择状态","clearable":""},model:{value:(_vm.formData.approve_status),callback:function ($$v) {_vm.$set(_vm.formData, "approve_status", $$v)},expression:"formData.approve_status"}},_vm._l((_vm.statusOption),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.title,"value":item.value}})}),1)],1),_c('SpFilterFormItem',{attrs:{"prop":"category_id"}},[_c('el-cascader',{attrs:{"placeholder":"商品分类","options":_vm.categoryList,"props":{ checkStrictly: true, value:'category_id' },"clearable":""},model:{value:(_vm.select_category_value),callback:function ($$v) {_vm.select_category_value=$$v},expression:"select_category_value"}})],1),_c('SpFilterFormItem',{attrs:{"prop":"item_bn"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入商品货号"},model:{value:(_vm.formData.item_bn),callback:function ($$v) {_vm.$set(_vm.formData, "item_bn", $$v)},expression:"formData.item_bn"}})],1)],1),_c('SpFinder',{ref:"finder",attrs:{"reserve-selection":"","row-key":_vm.rowKey,"other-config":{
      'max-height': 460,
      'header-cell-class-name': _vm.cellClass
    },"url":"/goods/items","show-pager-text":`已选中：${_vm.localSelection.length}`,"fixed-row-action":"","setting":_vm.setting,"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"select-all":_vm.handleSelectAll}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }