<style lang="scss">
.picker-goods {
  .sp-filter-form {
    padding: 8px 8px 0 8px;
  }

  .sp-finder-hd {
    display: none;
  }

  .sp-finder-hd {
    display: none;
  }

  .item-info {
    display: flex;

    .item-image {
      width: 60px;
      margin-right: 8px;
    }

    .sp-image {
      margin-right: 10px;
    }

    .item-name {
      color: #333;
      margin-top: 4px;
    }
  }

  .el-table {
    td {
      padding: 0;
    }
  }

  .disableheadselection {
    >.cell .el-checkbox__inner {
      display: none;
    }
  }

  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-goods">
    <SpFilterForm :model="formData" size="small" @onSearch="onSearch" @onReset="onSearch">
      <SpFilterFormItem v-if="isShowFormItem('distributor_id')" prop="distributor_id">
        <SpSelectShop v-model="formData.distributor_id" clearable placeholder="请选择店铺" @change="onSearch" />
      </SpFilterFormItem>
      <SpFilterFormItem prop="keywords">
        <el-input v-model="formData.keywords" clearable placeholder="请输入商品名称" />
      </SpFilterFormItem>
      <SpFilterFormItem prop="approve_status">
        <el-select
            v-model="formData.approve_status"
            placeholder="选择状态"
            clearable
          >
            <el-option
              v-for="item in statusOption"
              :key="item.value"
              :label="item.title"
              :value="item.value"
            >
            </el-option>
          </el-select>
      </SpFilterFormItem>
      <SpFilterFormItem prop="category_id">
        <el-cascader
          placeholder="商品分类"
          v-model='select_category_value'
          :options="categoryList"
          :props="{ checkStrictly: true, value:'category_id' }"
          clearable>
        </el-cascader>
      </SpFilterFormItem>
      <SpFilterFormItem prop="item_bn">
        <el-input v-model="formData.item_bn" clearable placeholder="请输入商品货号" />
      </SpFilterFormItem>
    </SpFilterForm>
    <SpFinder
      ref="finder"
      reserve-selection
      :row-key="rowKey"
      :other-config="{
        'max-height': 460,
        'header-cell-class-name': cellClass
      }"
      url="/goods/items"
      :show-pager-text="`已选中：${localSelection.length}`"
      fixed-row-action
      :setting="setting"
      :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }"
      @select="onSelect"
      @select-all="handleSelectAll"
    />
  </div>
</template>

<script>
import { SALES_STATUS } from '@/consts'
import { cloneDeep } from 'lodash'
import BasePicker from './base'
import PageMixin from '../mixins/page'
import SpSelectShop from '@/components/sp-select-shop'
import { createSetting } from '@shopex/finder'
import {getCategory} from '@/api/goods'

export default {
  name: 'PickerGoods',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择商品'
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  components: { SpSelectShop },
  data() {
    const { queryParams } = this.value
    const defaultParams = {
      keywords: '',
      distributor_id: '',
      approve_status: '',
      item_bn: '',
      category_id: ''
    }
    const formData = Object.assign(defaultParams, queryParams)
    return {
      formData,
      salesStatus: SALES_STATUS,
      list: [],
      multipleSelection: [],
      goodsBranchParams: {
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: ''
      },
      statusOption: [
        {
          title: '前台可销售',
          value: 'onsale'
        },
        {
          title: '可线下销售',
          value: 'offline_sale'
        },
        {
          title: '前台仅展示',
          value: 'only_show'
        },
        {
          title: '不可销售',
          value: 'instock'
        }
      ],
      multiple: this.value?.multiple ?? true,
      localSelection: [],
      rowKey: 'goods_id',
      categoryList: [],
      select_category_value: []
    }
  },
  computed: {
    setting() {
      return createSetting({
        columns: [
          { name: '商品ID', key: this.rowKey, width: 80 },
          {
            name: '商品名称',
            key: 'page_name',
            render: (h, { row }) =>
              h('div', { class: 'item-info' }, [
                h('div', { class: 'item-image' }, [
                  h('SpImage', {
                    props: {
                      src: row.pics[0],
                      width: 60,
                      height: 60
                    }
                  })
                ]),
                h('div', { class: 'item-name' }, row.itemName)
              ])
          },
          { name: '规格名称', key: 'item_spec_desc'},
          { name: '商品货号', key: 'item_bn', width: 150 },
          {
            name: '价格（¥）',
            key: 'price',
            width: 100,
            render: (h, { row }) => h('span', {}, row.price / 100)
          },
          { name: '库存', key: 'store', width: 100 }
        ]
      })
    }
  },
  watch: {
    localSelection: {
      handler(val) {
        this.updateVal(val)
      },
      deep: true
    }
  },
  created() {
    this.localSelection = cloneDeep(this.value.data) || []
    this.rowKey = this.value?.rowKey || 'goods_id'
  },
  mounted() {
    this.multiple = this.value?.pathLink ? true : this.value?.multiple ?? true
    this.getCategory()
  },
  methods: {
    beforeSearch(params) {
      params = {
        ...params,
        item_type: 'normal',
        ...this.formData,
        category: this.select_category_value.at(-1)
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.localSelection.length > 0) {
        const { finderTable } = this.$refs.finder.$refs
        const ids = this.localSelection.map((m) => m[this.rowKey])
        const selectRows = list.filter((item) => ids.includes(item[this.rowKey]))
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selectRows)
        })
      }
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onSelect(selection, row) {
      if (!this.multiple) {
        const { finderTable } = this.$refs.finder.$refs
        finderTable.clearSelection()
        this.localSelection = [row]
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selection.length > 0 ? [row] : [])
        })
      } else {
        const isAdd = selection.includes(row)
        const idx = this.localSelection.findIndex((f) => f[this.rowKey] === row[this.rowKey])
        if (isAdd && idx === -1) {
          this.localSelection.push(row)
        }
        if (!isAdd) {
          this.localSelection.splice(idx, 1)
        }
      }
    },
    /**
     * @description 全选只针对当前页 那么在状态改变的时候 应该不考虑未来的选中数据
     * @description 循环调用 onSelect 方法保存或删除当前页选中数据
     * @param list 当前页勾选数据 如果localSelection存在未来页数据 那么页码切换的时候 list中也会有
     */
    handleSelectAll(list) {
      // 当前页得数据
      const currentPageData = this.$refs.finder.$refs.finderTable.$refs.finderTable.list
      const currentPageDataIds = currentPageData.map((m) => m[this.rowKey])
      const currentPageSelectList = list.filter((item) => currentPageDataIds.includes(item[this.rowKey]))
      // list 中需要排除掉未来页的数据 才能保证当前页全选或者反选
      if (currentPageSelectList.length === 0) {
        currentPageData.forEach((item) => this.onSelect([], item))
      } else {
        currentPageData.forEach((item) => this.onSelect(currentPageData, item))
      }
    },
    isShowFormItem(key) {
      const { paramsFieldExclude = [] } = this.value
      return !paramsFieldExclude.includes(key)
    },
    getCategory() {
      getCategory().then(response => {
        this.categoryList = response.data.data
      })
    },
  }
}
</script>
